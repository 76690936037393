<template>
  <div v-html="result" class="markdown-body"></div>
</template>
<script>
import 'github-markdown-css'
var md = require('markdown-it')()
export default {
  props: {
    content: {
      type: String,
      default: '# markdown-it rulezz!'
    }
  },
  computed: {
    result() {
      return md.render(this.content)
    }
  }
}
</script>
<style lang="scss">
	.markdown-body {
		box-sizing: border-box;
		min-width: 200px;
		max-width: 980px;
		margin: 0 auto;
		padding: 30px;
	}

	@media (max-width: 767px) {
		.markdown-body {
			padding: 15px;
		}
	}
</style>
